import Vue from 'vue'
import Router from 'vue-router'
/*
import DevilsPunishment from './json/devils-punishment.json'
import HappinessFactors from './json/happinessfactors.json'
import CodeMonsters from './json/code-monsters.json'
import StuntPilot from './json/stunt-pilot.json'
import MinecraftGameDesign from './json/minecraft-game-design.json'
import ModDesignStudio from './json/mod-design-studio.json'
import JavaScriptCoding from './json/javascript-coding.json'
import AnimationStudio from './json/animation-studio.json'
*/
const TynkerSection: any = (allowed: Boolean) => {
  const TynkerSection: any = {
    title: 'Tynker',
    subtitle: 'Heavily Gamified Programming Courses',
    projects:
    [
      require('./json/javascript-coding.json'),
      require('./json/minecraft-game-design.json'),
      require('./json/mod-design-studio.json'),
      require('./json/code-monsters.json'),
      require('./json/stunt-pilot.json'),
      require('./json/animation-studio.json')
    ]
  }
  if (allowed) return TynkerSection
  for (let s of TynkerSection.projects) {
    s.thumb = '../img/logo.png'
    for (let p of s.imagesAndDescriptions) {
      p.img = '../img/logo.png'
    }
  }
  return TynkerSection
}
Vue.use(Router)
export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('./views/Portfolio.vue'),
      props: (route: any) => ({
        gigs: [
          {
            title: 'Versefera Studios',
            subtitle: '',
            projects:
            [
              require('./json/devils-punishment.json')
            ]
          },
          {
            title: 'Happiness Factors',
            subtitle: 'Certified High Performance Life Coach',
            projects:
            [
              require('./json/happinessfactors.json')
            ]
          },
          TynkerSection(route.query.pass === 'gameon' || route.query.pass === 'getitdone')
        ]
      })
    }
  ]
})
